;
(function ($, window, document, undefined) {
    var $win = $(window);
    var $doc = $(document);

    $doc.ready(function () {

        accountPopup();
        tableCheckboxes();
        profileDatepicker();
        addStudentPopup();
        hideAlert();
        filterSession();
        generateCertificates();
        toggleOtherSchoolField();
        validateForm();
        accordion();
        tabNavigation();
        stickyNavigation();
        filterModules();
        sortModules();
        toggleModuleDescription();
        toggleModule();
        filterCompetencies();
        addOwnModulePopup();
        checkPopup();
        filterObjectives();
        addReviewPopup();
        addTopicPopup();
        focusReply();
        updateThreadReply();
        deleteThreadReply();
        downloadAll();
        removePrompt();
        scrollToObjective();
        stickyCompetencyHeader();
        welcomeLetterPopup();
        areYouSureGradingForm();

        /*$('.dotdotdot').dotdotdot({
            wrap : 'letter',
            watch : true
        });*/

        /*
         hide alerts
          */
        function hideAlert() {
            $('.alert').on('click', function (e) {
                $(this).fadeOut();
            });
        }

        /*
         filter grading session
          */
        function filterSession() {
            $('select#year').on('change', function (e) {
                var url = $('.form-students-session form').attr('action');
                window.location = url + '/' + $(this).val();
            })
        }

        // custom select
        $('.select select').selecter();

        /*
         account settings popup
          */
        function accountPopup() {
            $('.has-popup.link-account').magnificPopup({
                type: 'ajax',
                showCloseBtn: false,
                callbacks: {
                    ajaxContentAdded: function () {
                        $('.select select').selecter();

                        // close popup
                        $('.popup-close').on('click', function (event) {
                            event.preventDefault();

                            $.magnificPopup.close();

                        });

                        profileDatepicker();
                        toggleOtherSchoolField()

                        $('.form-account form').submit(function (e) {
                            e.preventDefault();

                            form = $('.form-account form');

                            $.post(form.attr('action'), form.serializeArray(), function (data) {
                                $.magnificPopup.close();
                            })
                        });
                    }
                }
            });
        }

        /*
         add student popup
          */
        function addStudentPopup() {
            $('.has-popup.link-add, .has-popup.link-edit').magnificPopup({
                type: 'ajax',
                showCloseBtn: false,
                callbacks: {
                    ajaxContentAdded: function () {
                        // close popup
                        removePrompt();

                        $('.popup-close').on('click', function (event) {
                            event.preventDefault();

                            $.magnificPopup.close();

                        });
                    }
                }
            });
        }

        /*
         add review popup
         */
        function addReviewPopup() {
            $('.has-popup.link-write-review').magnificPopup({
                type: 'ajax',
                showCloseBtn: false,
                callbacks: {
                    ajaxContentAdded: function () {

                        clickRating();

                        // close popup
                        $('.popup-close').on('click', function (event) {
                            event.preventDefault();

                            $.magnificPopup.close();

                        });
                    }
                }
            });
        }

        /*
         add topic popup
         */
        function addTopicPopup() {
            $('.has-popup.link-add-topic').magnificPopup({
                type: 'ajax',
                showCloseBtn: false,
                callbacks: {
                    ajaxContentAdded: function () {

                        $('.select select').selecter();

                        // close popup
                        $('.popup-close').on('click', function (event) {
                            event.preventDefault();

                            $.magnificPopup.close();

                        });
                    }
                }
            });
        }

        function welcomeLetterPopup(){
            if ($('#welcome-popup').length) {
                $.magnificPopup.open({
                    items: {
                        src: '#welcome-popup',
                        type: 'inline'
                    }
                });
            }
        }

        /*
        Focus the textarea when clicking the "reply" button
        on the Thread Detail page
         */
        function focusReply(){
            $('.link-add-comment').click(function(e){

                $textarea = $('form #content');

                $textarea.focus();

                return false;
            });
        }

        /*
         Add your own module popup features
          */
        function addOwnModule(){
            $('.select select').selecter();

            // close popup
            $('.popup-close').on('click', function (event) {
                event.preventDefault();

                $.magnificPopup.close();

                location.reload();
            });

            $('.form-account form').submit(function (e) {
                e.preventDefault();

                form = $('.form-account form');

                $.post(form.attr('action'), form.serializeArray(), function (data) {
                    $.magnificPopup.close();
                })
            });

            // Clicking on "Create New" display the "Module name/Saving" form
            $('.form-custom-module form').on('submit', function() {
                $('.form-save-module').show();
                $('.form-update-module').hide();

                return false;
            });


            // Load the objectives of the selected custom module
            $('.form-custom-module form select').on('change', function(e){
                e.preventDefault();

                if ($(this).val() != 0){

                    // Setup the update form and the add objectives form with the selected module data
                    var selectedOption = $('.form-custom-module form select option:selected');

                    $('.form-update-module form #title').val(selectedOption.text());
                    $('.form-update-module form #module_id').val($(this).val());

                    $('.form-update-module form #has_assessment_update').prop('checked', selectedOption.attr('data-assessment') == 1);

                    $('.form-module-objectives #module_id').val($(this).val());

                    $('.form-save-module').hide();
                    $('.form-update-module').show();

                    var paramsUrl = '?module_id='+$(this).val()+'&objective_id='+$('.popup-competencies').attr('data-objective');
                    
                    $('.popup-competencies').load(
                        $('.popup-competencies').attr('data-url')+paramsUrl,
                        function(responseText, textStatus, jqXHR){
                            var meetObjectives = $('#has-objectives').val();

                            if (meetObjectives === 'true'){
                                $('.popup-add-own-content .module-key li:first-child').removeClass('hidden');
                            }
                            else {
                                $('.popup-add-own-content .module-key li:first-child').addClass('hidden');
                            }

                            accordion();
                            document.location.href = '#objective-'+$('.popup-competencies').attr('data-objective');
                    });
                    $('.popup-competencies').show();
                }
                else{
                    $('.form-update-module').hide();
                    $('.popup-competencies').hide();
                }
            });


            var module_id = $('#select-1').attr('data-module-id');
            if (typeof module_id != 'undefined') {
                $('#select-1').val(module_id);
                $('#select-1').trigger('change');
            }

            // Saving a custom module
            $('.form-save-module form').submit(function(e) {
                e.preventDefault();

                $.post($(this).attr('action'), $(this).serialize(), function(data){
                    $('.alert').html('<div class="alert alert-'+data.status+' alert-dismissible" role="alert"> <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>'+data.msg+'</div>');
                    $('.alert').fadeIn();
                    hideAlert();

                    if (data.status == 'success') {
                        $('.form-save-module').hide();
                        $('.popup-competencies').hide();
                        $('.form-custom-module form select')
                            .append($("<option></option>")
                                .attr("value", data.id)
                                .attr("selected", "selected")
                                .attr('data-assessment', data.has_assessment? 1 : 0)
                                .text(data.title));

                        $('.form-custom-module form select').val(data.id).change();

                        $('.select select').selecter('update');
                    }
                });

            });

            // Updating a custom module
            $('.form-update-module form').submit(function(e) {
                e.preventDefault();

                $.post($(this).attr('action'), $(this).serialize(), function(data){
                    $('.alert').html('<div class="alert alert-'+data.status+' alert-dismissible" role="alert"> <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>'+data.msg+'</div>');
                    $('.alert').fadeIn();
                    hideAlert();

                    if (data.status == 'success'){
                        $('.form-custom-module form select option:selected').text(data.title);
                        $('.form-custom-module form select option:selected').attr('data-assessment', data.has_assessment? 1 : 0);
                        $('.select select').selecter('update');
                    }
                });
            });

            // Adding objectives to custom module
            $('.form-module-objectives').submit(function(e) {
                e.preventDefault();

                $.post($(this).attr('action'), $(this).serialize(), function(data){
                    $('.alert').html('<div class="alert alert-'+data.status+' alert-dismissible" role="alert"> <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>'+data.msg+'</div>');
                    $('.alert').fadeIn();
                    hideAlert();

                    document.location.href = '#top';

                    if (data.status == 'success'){

                        var paramsUrl = '?module_id='+data.id+'&objective_id='+$('.popup-competencies').attr('data-objective');

                        $('.popup-competencies').load($('.popup-competencies').attr('data-url')+paramsUrl, function(responseText, textStatus, jqXHR){
                            var meetObjectives = $('#has-objectives').val();

                            if (meetObjectives === 'true'){
                                $('.popup-add-own-content .module-key li:first-child').removeClass('hidden');
                            }
                            else {
                                $('.popup-add-own-content .module-key li:first-child').addClass('hidden');
                            }
                            accordion();
                        });
                    }
                });
            });
        }

        /*
         Open Add your own module popup
          */
        function addOwnModulePopup() {
            $('.has-popup.add-module').magnificPopup({
                type: 'ajax',
                showCloseBtn: false,
                callbacks: {
                    ajaxContentAdded: function () {
                        addOwnModule();
                    }
                }
            });
        }

        function checkPopup(){
            $('.check-popup').each(function() {

                var $popupLink = $(this);

                $popupLink.on('click', function() {

                    $.magnificPopup.open({
                        type: 'ajax',
                        items: {
                            src: $(this).attr('data-url')
                        },
                        showCloseBtn: false,
                        callbacks: {
                            ajaxContentAdded: function() {
                                addOwnModule();
                            }
                        }
                    });
                });
            });
        }

        /*
         checkboxes
          */
        function tableCheckboxes() {
            $('.checkbox-select-all').find('input').on('change', function () {
                $(this)
                    .closest('table')
                    .find('tbody td:nth-child(' + ($(this).closest('th').index() + 1) + ') .checkbox:not(.disabled)  input[type="checkbox"]')
                    .prop('checked', this.checked)
                    .trigger("change");
            });

            $('.certificate-check').on('change', function () {
                $('.table-letter')
                    .find('tbody tr:nth-child(' + ($(this).parent().parent().parent().index() + 1) + ') input[type="checkbox"]')
                    .prop('checked', this.checked)

                $('.table-letter')
                    .find('tbody tr:nth-child(' + ($(this).parent().parent().parent().index() + 1) + ') .checkbox')
                    .toggleClass('disabled');
            });

            $('.checkbox-select-all').find('label').hover(function () {
                $(this)
                    .closest('table')
                    .find('tbody td:nth-child(' + ($(this).closest('th').index() + 1) + ') label')
                    .toggleClass('hover');
            });

            $('.table-letter .checkbox.disabled label').on('click', function (e) {
                $(".table-letter .checkbox.disabled .letter-check").prop('checked', true);
            });
            $(".table-letter .checkbox.disabled .letter-check").on('click', function(event){
                event.preventDefault();
            });
        }

        /*
         Submit certificate generation
          */
        function generateCertificates() {
            $('form.generate-cert').submit(function () {

                var certificatesNumber = $('.certificate-check:checked').length;
                var letterNumber = $('.letter-check:checked').length;

                if (certificatesNumber <= 0 && letterNumber <= 0) {
                    $('.alert').remove();
                    $('<div class="alert alert-danger alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>You need to select at least one certificate or letter to download.</div>').insertBefore('.generate-cert');
                    hideAlert();
                    return false;
                }
                else {
                    return true;
                }
            });
        }

        /*
         Datepickers
          */
        function profileDatepicker() {
            var opts = {
                altFormat: "yy-mm-dd"
            };

            $('#course_start_date_display').datepicker(
                $.extend({
                    altField: '#course_start_date'
                }, opts)
            );
            $('#course_end_date_display').datepicker(
                $.extend({
                    altField: '#course_end_date'
                }, opts)
            );
        }

        /*
         Show or hide the Other field for Medical school
          */
        function toggleOtherSchoolField() {
            $('.form-setup .select-secondary select[name="school_id"]').on('change', function(e){
                var selectedLabel = $('.form-setup .select-secondary select[name="school_id"] option:selected').text();
                if (selectedLabel == 'Other US Medical School' || selectedLabel == 'Non-US Medical School'){
                    $('input[name="medical_school"]').removeClass('hidden').attr('required', 'required');
                }
                else{
                    $('input[name="medical_school"]').addClass('hidden').removeAttr('required').val('');
                }
            });

            $('.form-account .select-secondary select[name="school_id"]').on('change', function(e){
                var selectedLabel = $('.form-account .select-secondary select[name="school_id"] option:selected').text();
                if (selectedLabel == 'Other US Medical School' || selectedLabel == 'Non-US Medical School'){
                    $('.other-school').removeClass('hidden');
                    $('input[name="medical_school"]').attr('required', 'required');
                }
                else{
                    $('.other-school').addClass('hidden');
                    $('input[name="medical_school"]').removeAttr('required').val('');
                }
            })
        }

        function validateForm() {
            if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
                $('form').validVal();
            }
        }

        function accordion() {
            $('.accordion-head').off('click').on('click', function(event) {
                if( ! $(event.target).hasClass('ico-square')) {
                    console.log('test');
                    $(this)
                        .next()
                        .stop(true, true)
                        .slideToggle()
                        .parent()
                        .toggleClass('expanded')
                        .siblings()
                        .removeClass('expanded')
                        .find('.accordion-body')
                        .stop(true, true)
                        .slideUp();
                }
            });
        }


        // nav
        //$('.nav ul ul').parent().addClass('has-dropdown');


        /*
         tabs
          */
        function tabNavigation() {
            $('.tabs-nav a').on('click', function (event) {
                var $tabLink = $(this);
                var $targetTab = $($tabLink.attr('href'));

                $tabLink
                    .parent() // go up to the <li> element
                    .add($targetTab)
                    .addClass('current')
                    .siblings()
                    .removeClass('current');

                event.preventDefault();
            });
        }

        /*
         sticky nav
          */
        function stickyNavigation() {
            if ($('.container').hasClass('module-detail')) {
                var $navSticky = $('.nav-sticky');
                $win.on('load scroll', function () {
                    var isHeaderFixed = $win.scrollTop() > 80;

                    $navSticky.toggleClass('nav-sticky-fixed', isHeaderFixed);
                });
            }
        }

        function filterModules(){

            $('.filter input').on( 'change', function() {

                var filterValue = '';

                $('.filter input:checked').each(function(e){
                    filterValue += $(this).attr('data-filter');
                });

                if (filterValue == ''){
                    $modules = $('.item.module-head');
                    $modules.show({
                        duration: '800',
                        easing: 'swing'
                    });
                    $('.count').html($modules.length);
                }
                else{
                    $modules = $('.item.module-head').filter(filterValue);
                    $('.item.module-head').filter(':not('+filterValue+')').hide({
                        duration: '800',
                        easing: 'swing'
                    });
                    $modules.show({
                        duration: '800',
                        easing: 'swing'
                    });

                    $numberModulesString = $modules.length+' Module';
                    if ($modules.length > 1) $numberModulesString += 's';
                    $('.count').html($numberModulesString);
                }

            });
        }

        function filterObjectives(){

            $('#show-skills').on( 'change', function() {

                console.log($(this).val());
                var filterValue = $(this).val();

                if (filterValue == ''){
                    $objectiveLegends = $('.legend-box');
                    $objectiveLegends.show({
                        duration: '800',
                        easing: 'swing'
                    });
                    $objectives = $('.table-goal tbody tr');
                    $objectives.show({
                        duration: '800',
                        easing: 'swing'
                    });
                    $objectives.parent().parent().parent().parent().parent().show({
                        duration: '800',
                        easing: 'swing'
                    });
                }
                else{
                    $objectives = $('.table-goal tbody tr').filter('.'+filterValue);
                    $objectiveLegends = $('.legend-box').filter('.'+filterValue);

                    $('.table-goal tbody tr').filter(':not('+'.'+filterValue+')').hide({
                        duration: '800',
                        easing: 'swing'
                    });
                    $('.table-goal tbody tr').filter(':not('+'.'+filterValue+')').parent().parent().parent().parent().parent().hide();
                    $('.legend-box').filter(':not('+'.'+filterValue+')').hide({
                        duration: '800',
                        easing: 'swing'
                    });
                    $objectiveLegends.show({
                        duration: '800',
                        easing: 'swing'
                    });
                    $objectives.show({
                        duration: '800',
                        easing: 'swing'
                    });
                    $objectives.parent().parent().parent().parent().parent().show({
                        duration: '800',
                        easing: 'swing'
                    });
                }

            });
        }

        function filterCompetencies(){
            $('.module-skills-filter .tooltip .tooltip-toggle').click(function(e){
                e.preventDefault();

                $(this).toggleClass('active');

                var filterValue = '';

                $('.module-skills .tooltip .tooltip-toggle.active').each(function(e){
                    filterValue += $(this).attr('data-filter')+',';
                });

                if (filterValue.length > 0) {
                    filterValue = filterValue.slice(0, -1);
                }

                if (filterValue == ''){
                    $competencies = $('.section-body .skill-block');
                    $competencies.show({
                        duration: '800',
                        easing: 'swing'
                    });
                }
                else{
                    $competencies = $('.section-body .skill-block').filter(filterValue);
                    $('.section-body .skill-block').filter(':not('+filterValue+')').hide({
                        duration: '800',
                        easing: 'swing'
                    });
                    $competencies.show({
                        duration: '800',
                        easing: 'swing'
                    });
                }

            })
        }

        function sortModules(){
            $('#sort').on('change', function(e){
                $('.form-sort form').submit();
            });
            $('#sortEssential').on('change', function(e){
                $('.form-sort.essential form').submit();
            });
        }

        function toggleModuleDescription(){
            $('.module-head .module-actions ul li:first-child a').on('click', function(e){
                e.preventDefault();
                $(this).parent().toggleClass('current');
                $('.module-'+$(this).attr('data-toggle')).toggleClass('expanded');
            })
        }

        function toggleModule(){
            $('.tooltip-toggle .checkbox form input[type=checkbox]').on('change', function(e){
               $(this).parent().submit();
            });
        }

        function clickRating(){
            $('.rating-bar a').click( function(e){

                e.preventDefault();

                var value = $(this).attr('data-value');

                console.log(value);

                $(this).parent().attr('data-rating', value);

                $('input[name=rating]').val(value);
            })
        }

        function updateThreadReply(){
            $('update-comment').click(function(e){
                var url = $(this).attr('href');
                var id = $(this).parent().find('.comment-id').val();
                var content = $(this).parent().find('.comment-content').val();
                var user_id = $(this).parent().find('.comment-user-id').val();
                var thread_id = $('input[name=thread_id]').val();

                //TODO POST to thread edit.
            });
        }

        function deleteThreadReply(){
            $('remove-comment').click(function(e){
                var id = $(this).parent().find('.comment-id').val();

                //TODO POST to thread delete.
            });
        }

        function downloadAll(){
            $('.btn.download-all').click(function(e){
                //Check all checkboxes
                $('.generate-cert input#certificate-check, .generate-cert input.certificate-check, .generate-cert input#letter-check, .generate-cert input.letter-check').prop('checked', true);

                var certificatesNumber = $('.certificate-check:checked').length;
                var letterNumber = $('.letter-check:checked').length;
                var knowledgeChecked = $('#knowledge:checked').length;
            })
        }

        function removePrompt(){
            $('.btn-danger').click(function(e){

                var r = confirm('Are you sure you want to remove it?');

                if (r == true) {

                }
                else {
                    e.preventDefault();
                }
            })
        }

        function scrollToObjective(){
            $('.legend-box').click(function(e){
                var objectiveId = $(this).attr('data-objective-id');

                if (typeof objectiveId != 'undefined'){

                    $('.accordion-body').hide();
                    $(this).closest('.accordion-section').siblings().removeClass('expanded');
                    $(this).closest('.accordion-section').addClass('expanded');
                    $(this).closest('.accordion-section').find('.accordion-body').show();

                    window.location.hash = '#objective-'+objectiveId;
                    window.scrollTo(window.scrollX, window.scrollY - 100);
                }
            })
        }

        function UpdateTableHeaders() {

            $(".persist-area").each(function() {

                var el             = $(this),
                    offset         = el.offset(),
                    scrollTop      = $(window).scrollTop(),
                    floatingHeader = $(".sticky", this)

                if ((scrollTop > offset.top) && (scrollTop < offset.top + el.height())) {
                    floatingHeader.css({
                        "visibility": "visible"
                    });
                } else {
                    floatingHeader.css({
                        "visibility": "hidden"
                    });
                };
            });
        }

        function stickyCompetencyHeader() {
            var clonedHeaderRow;

            $(".persist-area").each(function() {
                clonedHeaderRow = $(".skill-block-head", this);
                clonedHeaderRow
                    .before(clonedHeaderRow.clone())
                    .css("width", clonedHeaderRow.width())
                    .addClass("sticky");

            });

            $(window)
                .scroll(UpdateTableHeaders)
                .trigger("scroll");
        }

        function areYouSureGradingForm() {
            $('#grading-form').areYouSure();
        }

    });
})(jQuery, window, document);
